<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-autocomplete
          dense
          outlined
          hide-details
          v-model="idCustomer"
          :items="customerOptions"
          label="Khách hàng"
          placeholder="Khách hàng"
          class="c-input-small"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-file-input
          accept=".xlsx"
          v-model="file"
          label="Chọn file"
          placeholder="Chọn file"
          dense
          outlined
          hide-details
          class="c-input-small"
          append-icon="mdi-paperclip"
          prepend-icon=""
          :disabled="!idCustomer"
          @change="onInputFileChange"
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          color="error"
          block
          @click="resetAll"
          :disabled="!idCustomer || !file"
        >
          Làm lại
        </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          color="success"
          block
          @click="assignUid"
          :disabled="!idCustomer || !file"
        >
          Xác nhận
        </v-btn>
      </v-col>
    </v-row>

    <div class="mt-10">
      <v-simple-table
        fixed-header
        height="calc(100vh - 245px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">UID</th>
              <th class="text-center">Relevant ID</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in items"
              :key="`au_${key}`"
              class="text-center"
            >
              <td>{{ item.uid }}</td>
              <td>{{ item.relevant_id }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div
        class="text-right error--text fs-14px font-weight-medium font-italic pt-2"
      >
        Tổng: {{ items.length }}
      </div>
    </div>
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import { httpClient } from "@/libs/http";

export default {
  name: "AssignUid",
  data: () => ({
    isLoading: false,
    file: null,
    items: [],
    customers: [],
    idCustomer: null,
  }),
  computed: {
    customerOptions() {
      return [...this.customers].map((item) => ({
        text: item.company_name,
        value: item.id,
      }));
    },
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    resetAll() {
      this.items = [];
      this.file = null;
    },
    async getCustomers() {
      const { data } = await httpClient.post(
        "/customer/v1/get-all-has-contract",
        { is_active: true }
      );
      this.customers = [...data];
    },
    async assignUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;
      try {
        await httpClient.post("/dc/v1/assign-uid", {
          items: this.items,
          id_customer: this.idCustomer,
        });
        this.isLoading = false;
        this.resetAll();
        this.$vToastify.success("Gán UID thành công");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async onInputFileChange(file) {
      if (file) {
        const mappingFields = {
          UID: "uid",
          "Relevant ID": "relevant_id",
        };

        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const values = utils.sheet_to_json(worksheet);
          const items = [...values].map((v) => {
            const item = {};
            Object.keys(v).forEach((i) => {
              item[mappingFields[i]] = `${v[i]}`.trim();
            });
            return item;
          });
          this.validateData(items);
        };
        await reader.readAsArrayBuffer(file);
      }
    },
    validateData(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error("Không có dữ liệu");
        return false;
      }

      const checkNull = [...items].filter(
        (item) => !item.uid || !item.relevant_id
      );

      if (checkNull && checkNull.length > 0) {
        this.$vToastify.error("Không được để trống");
        return false;
      }

      this.items = [...items];
    },
  },
};
</script>
